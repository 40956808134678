import type { FC } from "react";
import clsx from "clsx";
import { ArrowLeft, ArrowRight } from "../../icons";
import { usePagination, ELLIPSIS } from "./hooks/usePagination";

export type PaginationProps = {
  currentPage: number;
  pageCount: number;
  onPageChange: (currentPage: number) => void;
  labels: {
    next: string;
    prev: string;
    page: string;
  };
  className?: string;
};

export const Pagination: FC<PaginationProps> = ({
  currentPage,
  pageCount,
  onPageChange,
  className,
  labels,
}) => {
  const hasPages = pageCount > 1;

  const pages = usePagination({ currentPage, totalPages: pageCount });

  const mappedPages = pages.map((page, index) => {
    if (page === ELLIPSIS) {
      return (
        <li
          className="w-8 aspect-square flex justify-center items-center h-fit font-bold"
          key={`ellipsis-${index}`}
        >
          <button type="button" className="ellipsis">
            &#8230;
          </button>
        </li>
      );
    }

    return (
      <li
        key={page}
        className={clsx(
          "w-8 aspect-square flex justify-center h-fit font-bold items-center",
          page === currentPage && " rounded-lg border border-primary-black"
        )}
      >
        <button
          type="button"
          className="inline-block min-w-[2ch] text-center"
          onClick={() => onPageChange(page as number)}
        >
          <span className="sr-only">{labels.page}</span> {page}
        </button>
      </li>
    );
  });

  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === pages.at(-1);

  const handleNextPage = () => hasPages && !isLastPage && onPageChange(currentPage + 1);
  const handlePreviousPage = () => hasPages && !isFirstPage && onPageChange(currentPage - 1);

  return (
    <nav aria-label="pagination" className={clsx("font-sans text-primary-black", className)}>
      <ul className="flex flex-row tablet:gap-x-2 items-center">
        <li
          className={clsx("p-2", {
            "text-grey-asphalt pointer-events-none": !hasPages || isFirstPage,
          })}
        >
          <button
            type="button"
            className={clsx(
              "flex flex-row items-center hover:underline underline-offset-2",
              isFirstPage && "cursor-default"
            )}
            onClick={handlePreviousPage}
          >
            <ArrowLeft width={16} className="mr-2" />
            <span className="sr-only tablet:not-sr-only">{labels.prev}</span>
            <span className="sr-only">{labels.page}</span>
          </button>
        </li>
        {mappedPages}
        <li
          className={clsx("p-2", {
            "text-grey-asphalt pointer-events-none": !hasPages || isLastPage,
          })}
        >
          <button
            type="button"
            className={clsx("flex flex-row items-center hover:underline underline-offset-2", {
              "cursor-default": !hasPages || isLastPage,
            })}
            onClick={handleNextPage}
          >
            <span className="sr-only tablet:not-sr-only">{labels.next}</span>
            <ArrowRight className="ml-2" width={16} />
            <span className="sr-only">{labels.page}</span>
          </button>
        </li>
      </ul>
    </nav>
  );
};
