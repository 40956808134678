import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";
import { ChevronDown } from "../../icons";

export interface AccordionItemProps {
  title: ReactNode | string;
  icon?: ReactNode;
  isExpanded?: boolean;
  content: React.ReactNode;
}

type FunctionalProps = { itemId: string; onClick: (id: string) => void };

const AccordionItem = ({
  title,
  content,
  icon,
  isExpanded,
  itemId,
  onClick,
}: AccordionItemProps & FunctionalProps) => {
  return (
    <li className="border-grey-light border-b">
      <h2>
        <button
          id={`accordion--id_${itemId}`}
          aria-expanded={isExpanded}
          aria-controls={`accordion-item--id_${itemId}`}
          onClick={() => onClick(itemId)}
          type="button"
          className="flex w-full items-center justify-between gap-5 py-4"
          data-testid="accordion-title"
        >
          <span className="flex gap-2">
            {icon}
            <span
              className={twMerge(
                "text-label-sm tablet:text-label font-sans font-semibold tracking-wide  block text-left",
                isExpanded && "text-secondary-aubergine"
              )}
            >
              {title}
            </span>
          </span>
          <ChevronDown
            aria-hidden="true"
            role="img"
            width={24}
            className={twMerge(
              "transition-1 duration-100 mr-2 flex-shrink-0",
              isExpanded ? "fill-secondary-aubergine rotate-180" : "rotate-0"
            )}
          />
        </button>
      </h2>

      {content && (
        <div
          role="region"
          aria-labelledby={`accordion--id_${itemId}`}
          aria-hidden={!isExpanded}
          className={twMerge(
            "overflow-hidden transition-all grid grid-rows-[0fr] duration-100 ease-in-out",
            isExpanded && "grid grid-rows-[1fr] pb-5"
          )}
        >
          <article className="overflow-hidden">{content}</article>
        </div>
      )}
    </li>
  );
};

export default AccordionItem;
