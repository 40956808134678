import { Logo } from "./img/HiltermannLogo";
import { HttpStatusCodes } from "./types";

export type HttpErrorProps = {
  code: HttpStatusCodes;
  labels?: {
    [key in HttpStatusCodes]?: {
      title: string;
      message: string;
    };
  };
};

const defaultLabels = {
  500: {
    title: "Serverfout! We werken eraan",
    message:
      "Probeer over enkele seconden de pagina te vernieuwen. Als je direct hulp nodig heb van onze klantenservice neem dan contact met ons op via 088 554 3900. Bedankt voor je geduld!",
  },
  404: {
    title: "Niet gevonden",
    message:
      "We hebben ons best gedaan maar het lijkt erop dat deze pagina niet (meer) bestaat of misschien verhuisd is. Je kunt natuurlijk altijd terugkeren naar de homepagina of het later opnieuw proberen.",
  },
};

export const HttpError = ({ labels = defaultLabels, code }: HttpErrorProps) => {
  const { title, message } = labels?.[code] ?? {};

  return (
    <div className="size-full bg-gradient-1 content-center">
      <Logo className="max-w-[50%] tablet:max-w-none tablet:-mt-36" />
      <p className="text-secondary-grapefruit text-intro font-serif tracking-wide text-center mt-20">
        {`Error code: ${code}`}
      </p>
      <h2 className="text-white text-center text-h1-sm font-bold font-sans tracking-wide">
        {title}
      </h2>
      <p className="text-center font-serif text-white max-w-[50%] mx-auto mt-8">{message}</p>
    </div>
  );
};
